<template>
  <div>
    <card style="margin-top: 10px; font-size: 14px">
      <template #content>
        <guideInfo :guider="tour.guide" :tour="tour"></guideInfo>
        <van-row style="color:#808080;margin-top:.1rem">
          <span>{{tour.list_name}}</span>
        </van-row>
        <tourInfoTop :tour="tour"></tourInfoTop>
        <van-row style="height: 20px; color: grey; margin-top: 10px" @click="toTourInfo(tour.id)">
          出发日期：{{ tour.start_date }}
        </van-row>
        <van-row style="color: grey" @click="toTourInfo(tour.id)">
          <van-col span="20">
            团队人数：{{ tour.tourists_count }}
          </van-col>
          <van-col span="4" style="text-align:right">
            <van-tag type="info" :color="tourStateStyle.color" 
              :text-color="tourStateStyle.text_color" round style="height:20px;">
              {{tour.status_text}}
            </van-tag>
          </van-col>
        </van-row>
      </template>
    </card>
  </div>
</template>

<script>
import card from "./Card.vue";
import guideInfo from "@/views/components/GuideInfo";
import tourInfoTop from './TourInfoTop.vue'
export default {
  components: {
    card,
    tourInfoTop,
    guideInfo
  },
  props: {
    tour: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
  }),
  computed: {
    tourStateStyle() {
      switch (this.tour.status) {
        case 0: return {color: "#4168EE12", text_color: "#4168EE"};
        case 1: return {color: "#4168EE", text_color: "#fff"};
        case 2: return {color: "#13C2C212", text_color: "#13C2C2"};
        case 3: return {color: "#6f6f6f23", text_color: "#606060"};
      }
      return ''
    }
  },
  methods: {
    toTourInfo(id) {
      this.$router.push(`/tour/info/${id}`)
    }
  },
};
</script>

<style>
</style>