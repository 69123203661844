<template>
  <div style="min-height:100vh;">
     <div class="header">
      <van-row gutter="10" >
        <van-col  span="12" @click.native="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back`_icon'"
            style="height: 25px;margin:.3rem 0 0 .2rem;"
          />
          <span class="title-text">行程列表</span>
        </van-col>
        <van-col span="12" style="padding:.04rem .2rem 0 0;">
          <div class="title-text" style="margin-top:.2rem;text-align:right" @click="$router.push('/tousu/main')">投诉记录</div>
        </van-col>
      </van-row>
    </div>
    <div style="margin-top:30px;padding:0 .2rem 4rem .2rem;height:90vh;overflow:auto;overflow-y:scroll">
      <van-pull-refresh v-model="isLoading" @refresh="refreshList">
        <van-list v-model="listLoading" :finished="listFinished" 
          :finished-text="allTours.length > 0?'没有更多了': ''" @load="loadPage">
            <tourCard :tour="tour" v-for="(tour, idx) in allTours" :key="idx"></tourCard>
        </van-list>
        <van-empty description="没有行程" v-if="allTours.length === 0" style="margin-top:.2rem"/>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import tourCard from "@/views/components/TourCard"
import titleLink from "@/views/components/TitleLink"
import {mapGetters} from 'vuex'
export default {
  components: {
    card, tourCard, titleLink
  },
  data: () => ({ 
    isLoading: false,
    listLoading: false,
    paging: {
      total_count: 0,
      total_pages: 0,
      current_page: 1,
      next_page: null
    }
  }),
  computed: {
    ...mapGetters('travel_list', ['allTours']),
    listFinished() {
      return this.paging.next_page == null
    }
  },
  created() {
    this.refreshList()
  },
  methods: {
    onClickBack() {
      this.$router.push("/home");
    },
    toGuiderInfo(id) {
      this.$router.push(`/guider/info/${id}`)
    },
    refreshList() {
      const _self = this
      this.$store.dispatch('travel_list/getAllTravelLists', '0,1,2,3').then((res) => {
        if(res.status === 200) {
          _self.isLoading = false
          _self.fillPaging(res)
        }
      })
    },
    loadPage() {
      const _self = this
      this.$store.dispatch('travel_list/getAllTravelLists', '0,1,2,3', 
        this.paging.next_page).then((res) => {
          if(res.status === 200) {
            _self.listLoading = false
            _self.fillPaging(res)
            _self.$store.dispatch('travel_list/syncTravelList', res.data)
          }
        })
    },
    fillPaging(res) {
      this.paging.total_count = res.total_count
      this.paging.total_pages = res.total_pages
      this.paging.current_page = res.current_page
      this.paging.next_page = res.next_page
    }
  },
};
</script>

<style scoped>
.row {
  font-size: 14px;
  color: grey;
}
.title-text {
  font-size: 25px; color: white;font-weight:bold;margin-left:.1rem;
}
</style>